export const TRANSLATIONS_EN = {
  // header translate

  cameroon_location: "Chapel obili - Yaounde, Cameroon",

  // ######################################
  // navbar translation
  home: "Home",
  about_us: "About Us",
  program: "Programs",
  news_outreach: "News and Outreaches",
  contact_us: "Contact Us",
  outreaches: "Outreaches",
  recent_activities: "Recent Activities",

  // ######################################
  // section1 translation
  // ######################################

  populating_kingdom: "POPULATING THE KINGDOM OF GOD",
  populating_kingdom_descr: "According to the divine mandate in Matthew 28:19",
  reduce_hades: "REDUCE AND EMPTYING THE PATHS OF HADES",
  building_disciples: "BUILDING AND EQUIPING DISCIPLES",
  revealing_christ: "REVEALING CHRIST IN US",

  // ######################################
  // About Us translation
  // ######################################

  who_are_we: "Who are we ?",
  swm_description: `is an interdenominational movement, it's a ministry out with
  fundamentally the purpose of fulfilling the Great Commission
  according to Matthew 28:19`,
  swm_text: `Go therefore and make disciples of all nations, baptizing
  them in the name of the Father, Son and of the Holy Spirit`,
  vision1:
    "Build a team of missionaries, impact them through the ministry of the Word and Prayer",
  vision2:
    "Lead them out for the harvest, application. This to say evangelism and mission",
  vision3:
    "Send them out to make disciples and serious follow-up through phone calls and visitations",
  vision4:
    "Partner with schools (mostly private and evening schools) to preach the gospel to their subject",
  vision5:
    "Most importantly, to go out  through the regions of Cameroon, the countries of Africa and beyond, to partner with churches therein, impact youths there, lead them out into the feild of the GOSPEL and KINGDOM Proclamation",

  mission1:
    "Partner with religious and other societal instituttions and individuals;",
  mission2:
    "Make disciples through the preaching of the gospel of the Kingdom;",
  mission3:
    "Empower and impart those disciples to impact and influence the different mountains of the society unto salvation for JESUS.",

  our_mission: "Our Mission",
  our_vision: "Our Vision",
  mission_text: `We are living to see this come through, that anytime we take
  mics, stand before individuals and groups of people, we desire
  to see heaven populated and hades paths reduced and emptied`,
  to_accomplish: "To accompish this, we :",

  // ######################################
  // Our Programs translation
  // ######################################
  our_programs: "Our Programs",
  word_exposition: "Word Exposition",
  prayer_fasting: "Prayer and Fasting retreats",
  door_evangelism: "Door to Door evangelism",
  gospel_tracks: "Gospel tracks and Bibles Distribution",
  provisions: "Provisions to Orphanages and widows",
  followups: "Follow-ups through diverse strategies",
  crusades: "Crusades and Salvific film projections",
  individuals_prayer: "Prayer for individuals, Ministry, Church, etc",
  discipleship: "Discipleship",
  seminars: "Seminars",

  // ######################################
  // Statistics translation
  // ######################################
  souls_encountered: "Souls Encoutered",
  souls_won: "Souls Won",
  cities_region: "Cities & Region",
  countries: "Countries",

  // ######################################
  // Contact translation
  // ######################################
  weare_open: "We are open to you",
  send_message: "SEND YOUR MESSAGE",
  enter_name: "Enter your name",
  enter_email: "Enter your email",
  how_help: "how can we help you ?",
  get_us: "Get To Us",

  // ######################################
  // Footer translation
  // ######################################
  quick_links: "Quick Links",
  get_notified: "GET NOTIFY ON OUR PROGRAMS",
};
