import React from "react";
import { BiMailSend, BiMap, BiPhone } from "react-icons/bi";
import logo from "../../assets/logo.jpg";
import { useTranslation } from "react-i18next";

function Contact() {
  const { t } = useTranslation();

  return (
    <div
      className="container-fluid contact-section pt-4 bg-white pb-4"
      id="contact__section"
    >
      <div className="container pb-4 mt-4">
        <div className="row justify-content-center">
          <div className="col-md-10 shadow p-3 rounded-3">
            <div className="row p-3">
              <div className="col-md-6 col-sm-12">
                <div className="d-flex gap-4 flex-column">
                  <div>
                    <span className="custom-title text-muted">
                      {t("contact_us")}
                    </span>
                  </div>

                  <div className="row">
                    <div className="col-2">
                      <img src={logo} width={50} alt="" />
                    </div>
                    <div className="col d-flex flex-column justify-content-center">
                      <span className="" style={{ fontWeight: 500 }}>
                        Soul Winners Movement / CETA
                      </span>
                      <span className="text-muted">{t("weare_open")}</span>
                    </div>
                  </div>

                  <div className="line-divider"></div>

                  <div
                    style={{ fontSize: 14 }}
                    className="d-flex flex-column g-4"
                  >
                    <div className="d-flex flex-row gap-3 text-muted">
                      <BiMap size={22} color="grey" />
                      <p>{t("cameroon_location")}</p>
                    </div>

                    <div className="d-flex flex-row gap-3 text-muted">
                      <BiPhone size={22} color="grey" />
                      <p>+ (237) 676 914 890 / 670 707 720</p>
                    </div>

                    <div className="d-flex flex-row gap-3 text-muted">
                      <BiMailSend size={22} color="grey" />
                      <p>soulwinners@gmail.com</p>
                    </div>

                    <div className="pb-4">
                      <button className="btn btn-primary bg-success rounded-pill">
                        {t("get_us")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-12 ms-">
                <form action="">
                  <div className="d-flex flex-column">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control custom__input p-3"
                        placeholder={t("enter_name")}
                      />
                    </div>

                    <div className="form-group mt-4">
                      <input
                        type="text"
                        className="form-control custom__input p-3"
                        placeholder={t("enter_email")}
                      />
                    </div>

                    <div className="form-group">
                      <textarea
                        name=""
                        id=""
                        placeholder={t("how_help")}
                        className="form-control mt-4 custom__input"
                        cols="30"
                        rows="5"
                      ></textarea>
                    </div>

                    <div>
                      <button className="btn btn-success w-100 mt-4 p-3">
                        {t("send_message")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
