import React from "react";
import "./Books.css";
import prayer from "../../assets/icons/prayer.png";
import bible from "../../assets/icons/bible.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import chjourney from "../../assets/books/chjourney.pdf";
import disciple from "../../assets/books/disciple.pdf";
import envgospel from "../../assets/books/envgospel.pdf";
import chjourneyI from "../../assets/books/chjourney.jpg";
import discipleI from "../../assets/books/disciple.jpg";
import envgospelI from "../../assets/books/envgospel.jpg";

const Book = (props) => {
  return (
    <Link
      to={props.books}
      target="blank"
      className="program__card  row col-lg-3 col-md-6 col-12 mb-4 mb-lg-0 align-items-center justify-content-center"
    >
      <div className="program__image pt-3 pb-3 ">
        <img
          src={props.image}
          width={"100%"}
          height={"100%"}
          style={{ borderRadius: "10%" }}
          alt=""
          srcset=""
        />
      </div>
      <span className="program__text text-center">{props.title}</span>
    </Link>
  );
};

function Books() {
  const { t } = useTranslation();
  return (
    <div
      className="container-fluid pb-4"
      id="program__section"
      style={{ backgroundColor: "whitesmoke" }}
    >
      <div className="container p-3">
        <div className="text-center custom-title pb-4 mt-4 mx-auto">
          {/* {t("our_programs")} */}
          Some Of Our Publications
        </div>
        <div className="row justify-content-center">
          <Book
            title={"THE CHRISTIAN JOURNEY"}
            image={chjourneyI}
            books={chjourney}
          />
          <Book
            title={"THE EVANGELIST AND THE GOSPEL"}
            image={envgospelI}
            books={envgospel}
          />
          <Book
            title={"A TEMPLATE OF DISCIPLESHIP FOR SPIRITUAL MATURITY"}
            image={discipleI}
            books={disciple}
          />
          {/* <Book title={t("word_exposition")} image={bible} books={chjourney} />
          <Book title={t("prayer_fasting")} image={prayer} books={disciple} />
          <Book title={t("prayer_fasting")} image={prayer} books={envgospel} /> */}
        </div>
      </div>
    </div>
  );
}

export default Books;
