import React from "react";
import { BiMailSend, BiMap, BiPhone } from "react-icons/bi";
// import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./Footer.css";

function Footer() {
  const { t } = useTranslation();

  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-4 col-xs-12">
            <div className="single_footer single_footer_address">
              <h4>{t("quick_links")}</h4>
              <ul>
                <li>
                  <Link to="/">{t("home")}</Link>
                </li>
                <li>
                  <Link to="/">{t("about_us")}</Link>
                </li>
                <li>
                  <Link to="/">{t("program")}</Link>
                </li>
                <li>
                  <Link to="/">{t("outreaches")}</Link>
                </li>
                <li>
                  <Link to="/">{t("recent_activities")}</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-sm-4 col-xs-12">
            <div className="single_footer">
              <h4>{t("contact_us")}</h4>
              <ul>
                <li>
                  <BiMap color="white" size={18} />
                  <Link href="/" className="mx-2">
                    {t("cameroon_location")}
                  </Link>
                </li>
                <li>
                  <BiPhone color="white" size={18} />
                  <Link href="/" className="mx-2">
                    + (237) 676 914 890 / 670 707 720
                  </Link>
                </li>
                <li>
                  <BiMailSend color="white" size={18} />
                  <Link href="/" className="text-lowercase mx-2">
                    soulwinners@gmail.com
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-4 col-sm-4 col-xs-12">
            <div className="single_footer single_footer_address">
              <h4>{t("get_notified")}</h4>
              <div className="signup_form">
                <form action="/" className="subscribe">
                  <input
                    type="text"
                    className="subscribe__input"
                    placeholder={t("enter_email")}
                  />
                  <button type="button" className="subscribe__btn">
                    <i className="fas fa-paper-plane"></i>
                  </button>
                </form>
              </div>
            </div>
            {/* <div class="social_profile">
              <ul>
                <li>
                  <a href="/">
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a href="/">
                    <FaTwitter />
                  </a>
                </li>
                <li>
                  <a href="/">
                    <FaYoutube />
                  </a>
                </li>
                <li>
                  <a href="/">
                    <FaInstagram />
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-sm-12 col-xs-12">
            <p className="copyright">
              Copyright © 2022 <a href="/">swm</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
