import React from "react";
import { BiLocationPlus, BiMailSend } from "react-icons/bi";
// import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { useTranslation } from "react-i18next";

function Header() {
  const { t } = useTranslation();

  return (
    <header
      className="pb-4 w-100 custom__header"
      style={{
        height: 70,
        background: "linear-gradient(105deg,#2ca417 ,#3c9409)",
        zIndex: 10,
        top: 0,
        position: "fixed",
      }}
    >
      <div className="container">
        <div className="d-flex justify-content-between">
          <div className="col-lg-8 col-12 d-flex flex-wrap ">
            <div className="d-flex">
              <p className="d-flex align-items-center  mb-0 text-white">
                <BiLocationPlus />
                <span className="mx-2" style={{ fontSize: 12 }}>
                  {t("cameroon_location")}
                </span>
              </p>
              <a
                href="mailto:soulwinners@gmail.com"
                className="d-flex align-items-center mx-4 mb-0 text-white"
              >
                <BiMailSend />
                <span className="mx-2" style={{ fontSize: 12 }}>
                  soulwinners@gmail.com
                </span>
              </a>
            </div>
          </div>

          <div className="col-lg-8 col-12 d-flex flex-wrap pt-1">
            {/* <FaFacebook style={{ marginLeft: 20, color: "white" }} />
            <FaWhatsapp style={{ marginLeft: 20, color: "white" }} />
            <FaInstagram style={{ marginLeft: 20, color: "white" }} /> */}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
