import React from "react";
import img1 from "../../assets/act1.jpg";
import maroua from "../../assets/img4.jpg";
import bctv from "../../assets/bctv.jpg";
import orphanage1 from "../../assets/orphanage1.jpg";
import crusade1 from "../../assets/crusade1.jpg";
import authority1 from "../../assets/authority1.jpg";
import Activity from "../../components/Activity";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "green",
        borderRadius: "50%",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "green",
        borderRadius: "50%",
      }}
      onClick={onClick}
    />
  );
}

function Activities() {
  const { t } = useTranslation();

  var settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    cssEase: "linear",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const activities = [
    {
      id: 1,
      image: img1,
      title: "Evangelism Program in the West Region of Cameroon",
      place: "Dschang - Cameroon",
      date: "17/12/2023",
    },
    {
      id: 2,
      image: bctv,
      title: "Preaching at BCTV on the theme THE EVANGELIST AND THE GOSPEL",
      place: "Dschang - Cameroon",
      date: "22/12/2023",
    },
    {
      id: 3,
      image: orphanage1,
      title: "Recent Visit at CIBAEEVA Orphanage to show forth God's love",
      place: "Dschang - Cameroon",
      date: "21/12/2022",
    },
    {
      id: 4,
      image: crusade1,
      title: "3 days Crusade under the theme LET THERE BE LIGHT",
      place: "Dschang - Cameroon",
      date: "17/12/2022",
    },
    {
      id: 5,
      image: maroua,
      title: "Open Air preaching in some carrefour and Door to Door Evangelism",
      place: "Maroua - Cameroon",
      date: "17/12/2021",
    },
    {
      id: 5,
      image: authority1,
      title:
        "Preaching the Gospel of Jesus to traditional and state authorities",
      place: "Dschang - Cameroon",
      date: "20/12/2022",
    },
  ];

  return (
    <div
      className="container-fluid activities__section pt-4 pb-4"
      id="activities__section"
      style={{ backgroundColor: "whitesmoke" }}
    >
      <div className="text-center custom-title pb-4 mt-4">
        {t("news_outreach")}
      </div>
      <div className="container mt-4 pb-4">
        <Slider {...settings}>
          {activities.map((item, key) => {
            return (
              <Activity
                key={key}
                image={item.image}
                title={item.title}
                date={item.date}
                place={item.place}
              />
            );
          })}
        </Slider>
      </div>
    </div>
  );
}

export default Activities;
