import React from "react";
import "./Component.css";

function Activity(props) {
  return (
    <div className="col-md-11 col-sm-8 mb-4 mx-2">
      <div className="card rounded-3 shadow custom__card">
        <div className="card-body p-0 overflow-hidden">
          <img src={props.image} alt={props.title} className="img-fluid" />
        </div>
        <div className="card-footer">
          <p className="fw-bold fs-6">{props.title}</p>

          <div className="d-flex flex-row justify-content-between pb-2">
            <span
              className="text-muted"
              style={{ fontWeight: 500, fontSize: 14 }}
            >
              {props.date}
            </span>

            <span className="text-muted" style={{ fontSize: 14 }}>
              {props.place}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Activity;
