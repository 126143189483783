import React from "react";
import "./Statistics.css";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";

const style = {
  paddingBottom: 40,
  //   backgrouImage: url("../assets/img1.jpg"),
};

function Statistics() {
  const { t } = useTranslation();
  return (
    <div
      className="container-fluid bg-secondary statistics__section"
      style={style}
    >
      <div className="container pt-4">
        {/* <div className="text-center custom-title text-white pb-4 mt-4">
          Some Statistics
        </div> */}
        <div className="row justify-content-center align-items-center mt-4 text-white">
          <div className="col-md-3 col-sm-12 row flex-column text-center">
            <span className="fw-bold fs-1">
              <CountUp start={0} end={5895} duration={3.75} separator=" " />
            </span>
            <p className="mt-2">{t("souls_encountered")}</p>
          </div>
          <div className="col-md-3 col-sm-12 row flex-column text-center">
            <span className="fw-bold fs-1">
              <CountUp start={0} end={3022} duration={3.75} separator=" " />
            </span>
            <p className="mt-2">{t("souls_won")}</p>
          </div>
          <div className="col-md-3 col-sm-12 row flex-column text-center">
            <span className="fw-bold fs-1">
              <CountUp start={0} end={10} duration={2.75} separator=" " />
            </span>
            <p className="mt-2">{t("cities_region")}</p>
          </div>
          <div className="col-md-3 col-sm-12 row flex-column text-center">
            <span className="fw-bold fs-1">
              <CountUp start={0} end={1} duration={2.75} separator=" " />
            </span>
            <p className="mt-2">{t("countries")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Statistics;
