import React from "react";
import "./Navbar.css";
import logo from "../assets/logo.jpg";
import { useTranslation } from "react-i18next";

function Navbar() {
  const { t } = useTranslation();
  return (
    <nav className="navbar navbar-expand-sm navbar-dark bg-white py-3 fixed-top shadow-lg mt-4 custom__navbar">
      <div className="container">
        <a
          href="/"
          className="navbar-brand d-flex align-itemscenter justify-content-center"
        >
          <div>
            <img src={logo} width={50} className="logo img-fluid" alt="" />
          </div>
          <div className="row g-0 mx-2 custom__logo__title">
            <span
              className=""
              style={{
                color: "green",
                fontWeight: "500",
                fontSize: 18,
                letterSpacing: 1,
              }}
            >
              Soul Winners
            </span>
            <span
              style={{
                color: "red",
                fontWeight: "500",
                fontSize: 14,
                letterSpacing: 1,
              }}
              className="text-uppercase"
            >
              Movement / CETA
            </span>
          </div>
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style={{ backgroundColor: "green" }}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
            <li className="nav-item">
              <a
                href="#home__section"
                className="nav-link current mx-4 fs-6 text-first"
              >
                {t("acceuil")}
              </a>
            </li>
            <li className="nav-item mx-4">
              <a href="#about__section" className="nav-link fs-6 text-first">
                {t("about_us")}
              </a>
            </li>
            <li className="nav-item mx-4">
              <a href="#program__section" className="nav-link fs-6 text-first">
                {t("program")}
              </a>
            </li>
            {/* <li className="nav-item mx-2">
              <a href="/" className="nav-link fs-6 text-first">
                Testimonials
              </a>
            </li> */}
            <li className="nav-item mx-2">
              <a
                href="#activities__section"
                className="nav-link fs-6 text-first"
              >
                {t("news_outreach")}
              </a>
            </li>
            <li className="nav-item mx-4">
              <a href="#contact__section" className="nav-link fs-6 text-first">
                {t("contact_us")}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
