import React from "react";
import "./About.css";
import robert from "../../assets/robert.jpg";
import desmond from "../../assets/img8.jpg";
import logo from "../../assets/logo.jpg";
import { FaCheck } from "react-icons/fa";
import { useTranslation } from "react-i18next";

function About() {
  const { t } = useTranslation();

  const vision = [
    {
      id: 1,
      label: t("vision1"),
    },
    {
      id: 2,
      label: t("vision2"),
    },
    {
      id: 3,
      label: t("vision3"),
    },
    {
      id: 4,
      label: t("vision4"),
    },
    {
      id: 5,
      label: t("vision5"),
    },
  ];

  const mission = [
    {
      id: 1,
      label: t("mission1"),
    },
    {
      id: 2,
      label: t("mission2"),
    },
    {
      id: 3,
      label: t("mission3"),
    },
  ];

  return (
    <>
      {/* Who we are */}
      <div
        className="container-fluid about__section"
        id="about__section"
        style={{ backgroundColor: "white" }}
      >
        <div className="container">
          <div className="row pb-4 justify-content-center align-items-center">
            <div className="col-md-6 col-sm-12 col-lg-6">
              <h2 className="custom-title">{t("who_are_we")}</h2>
              <p
                className="mt-4 fs-5"
                style={{ textAlign: "left", paddingRight: 30 }}
              >
                <span
                  className="text-uppercase"
                  style={{ color: "red", fontWeight: 500 }}
                >
                  Soul Winners Movement (SWM) / CETA &nbsp;
                </span>
                {t("swm_description")} &nbsp;
                <span className="fw-bold">
                  <i>{t("swm_text")}</i>
                </span>
              </p>
            </div>
            <div className="col-md-6 col-sm-12 col-lg-6 text-center d-flex justify-content-center">
              <img
                src={logo}
                alt="logo of swm"
                data-aos="fade-down"
                data-aos-duration="1000"
                width={400}
                className="d-block img-fluid rounded "
              />
            </div>
          </div>
        </div>
      </div>

      {/* Our Mission */}
      <div
        className="container-fluid about__section"
        style={{ backgroundColor: "whitesmoke" }}
      >
        <div className="container">
          <div className="row pb-4">
            <div className="col-md-6 col-sm-12 col-lg-6">
              <img
                src={desmond}
                alt=""
                className="d-block shadow-sm img-fluid rounded"
              />
            </div>

            <div className="col-md-6 col-sm-12 col-lg-6 px-4 mt-4">
              <h2
                className="custom-title"
                data-aos="fade-down"
                data-aos-duration="2000"
              >
                {t("our_mission")}
              </h2>
              <h4
                className="mt-4"
                style={{ color: "red" }}
                data-aos="fade-down"
                data-aos-duration="1500"
              >
                Habakkuk 2:14
              </h4>
              <p className="mt-4" data-aos="fade-down" data-aos-duration="1000">
                {t("mission_text")}
              </p>

              <p className="mt-2" data-aos="fade-down" data-aos-duration="1000">
                {t("to_accomplish")}
              </p>

              {mission.map((item, index) => {
                return (
                  <div
                    className="d-flex justify-content-start align-items-start"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                  >
                    <span>
                      <FaCheck color="green" size={22} />
                    </span>
                    <p className="mx-2 ps-2" style={{ fontWeight: 400 }}>
                      {item.label}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/*Our Vision  */}
      <div className="container-fluid about__section">
        <div className="container">
          <div className="row pb-4">
            <div className="col-md-6 col-sm-12 col-lg-6">
              <h2
                className="custom-title pb-4"
                data-aos="fade-down"
                data-aos-duration="2000"
              >
                {t("our_vision")}
              </h2>
              {vision.map((item, index) => {
                return (
                  <div
                    className="d-flex justify-content-center align-items-start"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                  >
                    <span>
                      <FaCheck color="green" size={22} />
                    </span>
                    <p className="mx-2 ps-2" style={{ fontWeight: 400 }}>
                      {item.label}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="col-md-6 col-sm-12 col-lg-6">
              <img src={robert} alt="" className="d-block img-fluid rounded" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
