import React from "react";
import { Carousel } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import img2 from "../../assets/act1.jpg";
import img1 from "../../assets/img2.jpg";
import img3 from "../../assets/img4.jpg";
import img4 from "../../assets/img7.jpg";
import "./Hero.css";

function Hero() {
  const { t } = useTranslation();

  const data = [
    {
      id: 1,
      img: img1,
      title: t("populating_kingdom"),
      description: t("populating_kingdom_descr"),
    },
    {
      id: 2,
      img: img2,
      title: t("reduce_hades"),
      description: t("populating_kingdom_descr"),
    },
    {
      id: 3,
      img: img3,
      title: t("building_disciples"),
      description: t("populating_kingdom_descr"),
    },
    {
      id: 4,
      img: img4,
      title: t("revealing_christ"),
      description: t("populating_kingdom_descr"),
    },
  ];
  return (
    <div className="section1-container" id="home__section">
      <Carousel fade>
        {data.map((slide, index) => {
          return (
            <Carousel.Item>
              <img
                src={slide.img}
                alt={slide.title}
                className="align-self-cente carousel__images"
              />
              <Carousel.Caption>
                <h1
                  data-aos="fade-down"
                  data-aos-duration="2000"
                  className="fw-bolder text-uppercase"
                  style={{ color: "greenyellow" }}
                >
                  {slide.title}
                </h1>
                <p>{slide.description}</p>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
}

export default Hero;
