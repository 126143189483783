import React from "react";
import "./Programs.css";
import hands from "../../assets/icons/hands.png";
import cross from "../../assets/icons/cross.png";
import prayer from "../../assets/icons/prayer.png";
import receive from "../../assets/icons/receive.png";
import bible from "../../assets/icons/bible.png";
import heart from "../../assets/icons/heart.png";
import discipleship from "../../assets/icons/discipleship.png";
import seminars from "../../assets/icons/seminars.png";
import { useTranslation } from "react-i18next";

const Program = (props) => {
  return (
    <div className="program__card  row col-lg-3 col-md-6 col-12 mb-4 mb-lg-0 align-items-center justify-content-center">
      <div className="program__image pt-3 pb-3 ">
        <img
          src={props.image}
          width={"100%"}
          height={"100%"}
          style={{ borderRadius: "40%" }}
          alt=""
          srcset=""
        />
      </div>
      <span className="program__text text-center">{props.title}</span>
    </div>
  );
};

function Programs() {
  const { t } = useTranslation();
  return (
    <div
      className="container-fluid pb-4"
      id="program__section"
      style={{ backgroundColor: "whitesmoke" }}
    >
      <div className="container p-3">
        <div className="text-center custom-title pb-4 mt-4 mx-auto">
          {t("our_programs")}
        </div>
        <div className="row justify-content-center">
          <Program title={t("word_exposition")} image={bible} />
          <Program title={t("prayer_fasting")} image={prayer} />
          <Program title={t("door_evangelism")} image={heart} />
          <Program title={t("gospel_tracks")} image={bible} />
          <Program title={t("provisions")} image={receive} />
          <Program title={t("followups")} image={hands} />
          <Program title={t("crusades")} image={cross} />
          <Program title={t("individuals_prayer")} image={prayer} />
          <Program title={t("discipleship")} image={discipleship} />
          <Program title={t("seminars")} image={seminars} />
        </div>
      </div>
    </div>
  );
}

export default Programs;
