import React from "react";
import Footer from "../../partials/Footer";
import Header from "../../partials/Header";
import Navbar from "../../partials/Navbar";
import Statistics from "../../partials/Statistics";
import About from "../About/About";
import Activities from "../Activities/Activities";
import Books from "../Books/Books";
import Contact from "../Contact/Contact";
import Hero from "../Hero/Hero";
import Language from "../Language/Language";
import Programs from "../Programs/Programs";

function Home() {
  return (
    <>
      <Header />
      <Navbar />
      <Hero />
      <About />
      <Programs />
      <Statistics />
      <Activities />
      <Books />
      <Contact />
      <Language />
      <Footer />
    </>
  );
}

export default Home;
