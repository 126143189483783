export const TRANSLATIONS_FR = {
  // header translate

  cameroon_location: "Chapel obili - Yaoundé, Cameroun",

  // navbar translation
  home: "Accueil",
  about_us: "A propos de nous",
  program: "Programmes",
  news_outreach: "Nouvelles et sensibilisation",
  contact_us: "Contactez-nous",
  outreaches: "Sensibilisation",
  recent_activities: "Activités Récentes",

  // ######################################
  // section1 translation
  // ######################################

  populating_kingdom: "POPULER LE ROYAUME DE DIEU",
  populating_kingdom_descr: "Selon le mandat divin dans Matthieu 28:19",
  reduce_hades: "RÉDUIRE ET VIDé LES CHEMINS DE L'ENFERS",
  building_disciples: "FORMER ET ÉQUIPER DES DISCIPLES",
  revealing_christ: "RÉVÉLER LE CHRIST EN NOUS",

  // ######################################
  // About Us translation
  // ######################################

  who_are_we: "Qui sommes nous ?",
  swm_description: `est un mouvement interdenominationel, c'est un ministère avec
  fondamentalement le but d'accomplir la Grande Commission
  selon Matthieu 28:19`,
  swm_text: `Allez donc et faites de toutes les nations des disciples, les baptisant au nom du Père, du Fils et du Saint-Esprit`,
  vision1:
    "Construisez une équipe de missionnaires, impactez-les à travers le ministère de la Parole et de la Prière",
  vision2:
    "Conduisez-les pour la récolte, l'application. Ceci pour dire évangélisation et mission",
  vision3:
    "Envoyez-les faire des disciples et un suivi sérieux par le biais d'appels téléphoniques et de visites",
  vision4:
    "S'associer avec des écoles (principalement des écoles privées et du soir) pour prêcher l'évangile à leur sujet",
  vision5:
    "Plus important encore, parcourir les régions du Cameroun, les pays d'Afrique et au-delà, s'associer aux églises qui s'y trouvent, avoir un impact sur les jeunes là-bas, les conduire dans le domaine de la proclamation de l'ÉVANGILE et du ROYAUME",

  mission1:
    "Établissons des partenariats avec des institutions religieuses et d'autres institutions sociétales et des individus ;",
  mission2:
    "Batissons des disciples par la prédication de l'évangile du Royaume;",
  mission3:
    "Renforcons et donnonts à ces disciples un impact et une influence sur les différentes montagnes de la société pour le salut de JÉSUS.",

  our_mission: "Notre Mission",
  our_vision: "Notre Vision",
  mission_text: `Nous vivons pour voir cela se concrétiser, que chaque fois que nous prenons
  micros, debout devant des individus et des groupes de personnes, nous désirons
  voir le ciel peuplé et les chemins de l'enfer réduits et vidés`,
  to_accomplish: "Pour y parvenir, nous :",

  // ######################################
  // Our Programs translation
  // ######################################
  our_programs: "Nos Programmes",
  word_exposition: "Partage de la Parole",
  prayer_fasting: "Retraites de prière et de jeûne",
  door_evangelism: "Évangélisation porte à porte",
  gospel_tracks: "Brochures d'Evangile et Distribution de Bibles",
  provisions: "Dons aux orphelinats et aux veuves",
  followups: "Suivis à travers diverses stratégies",
  crusades: "Croisades et projections de films pour le salut",
  individuals_prayer:
    "Prière pour les particuliers, le ministère, l'église, etc.",
  discipleship: "Discipolat",
  seminars: "Séminaires",

  // ######################################
  // Statistics translation
  // ######################################
  souls_encountered: "Âmes rencontrées",
  souls_won: "Âmes gagnées",
  cities_region: "Villes & Région",
  countries: "Pays",

  // ######################################
  // Contact translation
  // ######################################
  weare_open: "Nous sommes ouvert vous",
  send_message: "ENVOYER VOTRE MESSAGE",
  enter_name: "Entrer votre nom",
  enter_email: "Entrer votre email",
  how_help: "Comment pouvons nous vous aider ?",
  get_us: "Nous contactez",

  // ######################################
  // Footer translation
  // ######################################
  quick_links: "Liens Rapides",
  get_notified: "SOYEZ NOTIFIEZ SUR NOS PROGRAMMES",
};
